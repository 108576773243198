<template>
	
	<div class="middleground">
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<div class="banner">
			<div class="banner-body flex">
				<div class="banner-body-title flex">
					<div style="width: 55%;align-items: initial;flex-direction: column;line-height: 0.50rem;" class="flex">
						<span style="font-size: 0.30rem; color: #FFFFFF;font-family: Source Han Sans CN;">力瀚数字化业务中台</span>
						<span
							style="font-size: 0.17rem;color: #fff;font-family: Source Han Sans CN;">会员/商品订单/营销全渠道一体化融合</span>
						<div class="sqsy" @click="applyFromshow = true">申请试用</div>
					</div>
				</div>
				<div class="banner-body-img flex">
					<img style="width: 2.70rem;" src="../assets/img/business-banner.png">
				</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/Journalismbanner.png">
		</div>

		<div class="transformation">
			<div class="worry-tit">
				<span class="worry-tit-t">企业数字化转型</span><br />
				<span class="worry-tit-b">业务中台是支持多个前端业务且具备共性能力的组织，有助于加速业务创新和数字化转型，为企业数据资产增值裂变提供动力支持。 </span>
			</div>
			<div class="transformation-box">
				<img style="width: 100%;" src="../assets/img/middleground-bus-img1.png">
			</div>
		</div>

		<div class="agreement">
			<div class="worry-tit">
				<span class="worry-tit-t">跨系统、跨协议服务能力互通</span><br />
				<span class="worry-tit-b">通过统一的AP接口进行服务能力共享，为企业提供发布、管理和监控能力支撑。实现内部多系统或企业与第三方合作平台系统间能力互通。</span>
			</div>
			<div class="transformation-box">
				<img  style="width: 100%;" src="../assets/img/middleground-bus-img2.png">
			</div>
		</div>

		<div class="visualization">
			<div class="worry-tit">
				<span class="worry-tit-t">全渠道业务一体化、可视化</span><br />
			</div>
			<div class="transformation-box flex">
				<div class="transformation-box-cell" v-for="(item,index) in visualizationList" :key="index">
					<img style="width: 0.40rem;" :src="item.img">
					<div class="transformation-box-cell-t">{{item.title}}</div>
					<div class="transformation-box-cell-b">{{item.lable}}</div>
				</div>

			</div>
		</div>

		<div class="scene">
			<div class="worry-tit">
				<span class="worry-tit-t">适合搭建中台的业务场景</span><br />
			</div>
			<div class="scene-box flex">
				<div class="scene-box-c flex">
					<div class="scene-box-c-imgbox">
						<img style="width: 1.4rem;" src="../assets/img/middleground-scene1.png">
						<img style="width: 0.29rem;" class="scene-box-c-imgbox-img" src="../assets/img/middleground-scene1-1.png">
					</div>
					<div class="scene-box-c-text">
						<div style="font-size: 0.13rem;color: #000000;">系统多、数据多，运营单一化</div>
						<div style="color: #999999;font-size: 0.09rem;margin-top: 0.10rem;">各系统割裂，形成数据孤岛，无法基于现 有系统制定更高效策略。
						</div>
						<div style="color: #999999;font-size: 0.09rem;margin-top: 0.10rem;">大量重复建设，缺乏业务核心能力沉淀和 支撑，缺乏长效机制。</div>
					</div>
				</div>

				<div class="scene-box-c flex">
					<div class="scene-box-c-imgbox">
						<img style="width: 1.4rem;" src="../assets/img/middleground-scene2.png">
						<img style="width: 0.29rem;" class="scene-box-c-imgbox-img" src="../assets/img/middleground-scene2-2.png">
					</div>
					<div class="scene-box-c-text">
						<div style="font-size: 0.13rem;color: #000000;">业务不在线，不实时</div>
						<div style="color: #999999;font-size: 0.09rem;margin-top: 0.10rem;">信息化程度不足，大量数据果用人工统 计。</div>
						<div style="color: #999999;font-size: 0.09rem;margin-top: 0.10rem;">核心数据更新不及时，且不支持实时在线 查看核对。</div>
					</div>
				</div>

				<div class="scene-box-c flex">
					<div class="scene-box-c-imgbox">
						<img style="width: 1.4rem;" src="../assets/img/middleground-scene3.png">
						<img class="scene-box-c-imgbox-img" style="right: 15px;width: 0.29rem;"
							src="../assets/img/middleground-scene3-3.png">
					</div>
					<div class="scene-box-c-text" style="margin-left: 0;padding-left: 0;">
						<div style="font-size: 0.13rem;color: #000000;">消费场变化快，弹性高</div>
						<div style="color: #999999;font-size: 0.09rem;margin-top: 0.10rem;">需要快速应对消费者带来的多样化场景需 求。</div>
						<div style="color: #999999;font-size: 0.09rem;margin-top: 0.10rem;">对业务支撑有较高的稳定性和单性伸缩要 求。</div>
					</div>
				</div>

				<div class="scene-box-c flex">
					<div class="scene-box-c-imgbox">
						<img style="width: 1.4rem;" src="../assets/img/middleground-scene4.png">
						<img style="width: 0.29rem;" class="scene-box-c-imgbox-img" src="../assets/img/middleground-scene4-4.png">
					</div>
					<div class="scene-box-c-text">
						<div style="font-size: 0.13rem;color: #000000;">业务数字化进程难</div>
						<div style="color: #999999;font-size: 0.09rem;margin-top: 0.10rem;">业务数字化瓶颈，创新因难，无法支撑市 场高速转变。</div>
						<div style="color: #999999;font-size: 0.09rem;margin-top: 0.10rem;">会员分散化运营，道扁平化管理。</div>
					</div>
				</div>
			</div>
		</div>

		<div class="programme">
			<div class="programme-tit">全渠道业务中台联动，让数据更有价值</div>
			<div class="programme-but flex">
				<div class="programme-but-input">想了解更多产品？</div>
				<div class="programme-but-but flex" @click="applyFromshow = true">点击申请</div>
			</div>
		</div>
	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	export default {
		data() {
			return {
				applyFromshow: false,
				visualizationList: [{
						img: require('../assets/img/middleground-icon1.png'),
						title: '会员中心',
						lable: '对全触点上所有客户进行统识别 和管理，提供线上线下全域一致客 户体验。'
					},
					{
						img: require('../assets/img/middleground-icon2.png'),
						title: '营销中心',
						lable: '支持限时折扣、满成满折、阶梯式 促销等需求，兼容线上线下，促销 效果统一管控'
					},
					{
						img: require('../assets/img/middleground-icon3.png'),
						title: '商品中心',
						lable: '提供商品导入、信息调整、上下 架、打标签等操作，实现多平台/多 店铺多门店商品体化管理'
					},
					{
						img: require('../assets/img/middleground-icon4.png'),
						title: '订单中心',
						lable: '各渠首订单统一归集，共享库存和 退款管理，统一并单、折单。'
					},
					{
						img: require('../assets/img/middleground-icon5.png'),
						title: '库存中心',
						lable: '全渠道库存信息同步，及时掌握各 渠道库存余量，规避超卖断货风 险。'
					},
					{
						img: require('../assets/img/middleground-icon6.png'),
						title: '物流中心',
						lable: '实现物流订单的统一追踪、异常反 馈、统一结算、自动分配能力。'
					},
					{
						img: require('../assets/img/middleground-icon7.png'),
						title: '分销中心',
						lable: '多渠道分销体化整合，业务体系 统一管理、统一调配、统一策略 节省运营成本，提升运营效率。'
					},
					{
						img: require('../assets/img/middleground-icon8.png'),
						title: '结算中心',
						lable: '提供账务档案管理、应收应付、返 利、(月度结算等综合功能，支持 对接第三方、银行等支付首能 力。'
					},
					{
						img: require('../assets/img/middleground-icon9.png'),
						title: '协同中心',
						lable: '达成全渠道全务线工作流转协 同，智能事务分配，提高流转效 率。'
					},
					{
						img: require('../assets/img/middleground-icon10.png'),
						title: '服务中心',
						lable: '企业级商品、订单、会员等业务中 台搭建，多维度运营体系搭建扶 持，活动效果分析&复盘。'
					},
					{
						img: require('../assets/img/middleground-icon11.png'),
						title: '内容中心',
						lable: '全触点营销内容统一制作、管理 分发，支持内容言销任务分配业 绩考核功能。'
					},
					{
						img: require('../assets/img/middleground-icon12.png'),
						title: '消息中心',
						lable: '提供消分发功能，支持短信邮件 微信多类型触达，保证消息安全 支持效果数据分析。'
					}
				]
			}
		},
		components: {
			// Canvas，
			applyFrom
		},
		
		methods:{
			close(){
				this.applyFromshow = false;
			},
		}
		
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 750px) {
		.scene-box{
			width: 80% !important;
		}
		.banner-body-title{
			width: 50% !important;
			justify-content: initial !important;
		}
		
		.transformation-box{
			width: 80% !important;
		}
	}
	.banner {
		width: 100%;
		height: 3.00rem;
		// padding-top: 30px;
		position: relative;
		z-index: 101;

		.banner-body {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;

			// padding: 40px 0;
			.banner-body-img {
				width: 35%;
				height: 100%;
				text-align: left;
				justify-content: initial;
			}

			.banner-body-title {
				width: 44%;
				height: 100%;
				// flex-direction: column;
				text-align: left;
				// justify-content: right;

				// align-items: initial;
				.sqsy {
					width: 1.80rem;
					height: 0.50rem;
					border-radius: 0.06rem;
					// margin: 0 auto;
					margin-top: 0.20rem;
					background-image: url(../assets/img/sqsy.png);
					font-size: 0.25rem;
					text-align: center;
					line-height: 0.50rem;
					font-size: 0.24rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		}

		// background-image: url(../assets/img/Journalismbanner.png);
	}

	.transformation {
		width: 100%;
		padding: 0.80rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666;
			}
		}

		.transformation-box {
			width: 12.42rem;
			// width: 66%;
			margin: 0 auto;
			margin-top: 0.80rem;
		}
	}

	.agreement {
		width: 100%;
		padding: 0.80rem 0;
		background-color: #f7f7f7;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666;
			}
		}

		.transformation-box {
			// width: 66%;
			width: 12.41rem;
			margin: 0 auto;
			margin-top: 0.80rem;
		}
	}

	.visualization {
		width: 100%;
		padding: 0.80rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}
		}

		.transformation-box {
			width: 50%;
			margin: 0 auto;
			margin-top: 0.80rem;
			justify-content: space-between;
			flex-wrap: wrap;

			.transformation-box-cell {
				width: 23%;
				margin-bottom: 0.40rem;

				.transformation-box-cell-t {
					font-size: 0.14rem;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #333333;
					// margin-top: 0.15rem;
				}

				.transformation-box-cell-b {
					font-size: 0.09rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #999999;
					margin-top: 0.20rem;
				}
			}
		}
	}

	.scene {
		width: 100%;
		padding: 0.80rem 0;
		background-color: #f7f7f7;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}
		}

		.scene-box {
			width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;
			justify-content: space-between;
			flex-wrap: wrap;

			.scene-box-c {
				width: 49%;
				margin-bottom: 0.40rem;

				.scene-box-c-imgbox {
					position: relative;

					.scene-box-c-imgbox-img {
						width: 0.29rem;
						position: absolute;
						right: -0.18rem;
						top: -0.10rem;
					}
				}

				.scene-box-c-text {
					flex: 1;
					text-align: left;
					padding: 0 0.10rem;
					margin-left: 0.20rem;
				}
			}
		}
	}

	.programme {
		width: 100%;
		height: 2.00rem;
		background-image: url(../assets/img/dg-bg.png);

		.programme-tit {
			width: 100%;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			padding: 0.40rem 0;
		}

		.programme-but {
			// width: 40%;
			margin: 0 auto;

			.programme-but-input {
				width: 3.20rem;
				height: 0.45rem;
				border: 1px solid #ccc;
				font-size: 0.15rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.45rem;
			}

			.programme-but-but {
				width: 1.50rem;
				height: 0.45rem;
				font-size: 0.20rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 0.60rem;
				border-radius: 5px;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
			}
		}
	}
</style>
